import { translate as _ } from '@ngneat/transloco';
import { FormatterService, Option, Str, TableFilter, TableFilterService } from '@web/birdz-angular';

export function getTableFilters(
  tableFilterService: TableFilterService,
  formatter: FormatterService
) {
  const filters = new Map<string, TableFilter<any>>();
  const sorter = Str.sortBy('label');
  const yesNoOptions: Option<number>[] = [
    { label: _('YES'), value: 1 },
    { label: _('NO'), value: 0 },
  ].sort(sorter);
  const modeOptions: Option<string>[] = [
    { label: _('FIX_NETWORK'), value: 'FN' },
    { label: _('WALK_BY'), value: 'WB' },
  ].sort(sorter);
  const raccordeOptions: Option<number>[] = [
    { label: _('OPTION_CONNECTED'), value: 1 },
    { label: _('OPTION_NOT_CONNECTED'), value: 0 },
  ].sort(sorter);
  const etatSupervisionOptions: Option<string | null>[] = [
    { label: _('FUNCTIONAL'), value: 'OK' },
    { label: _('PRESUMED_NON_COMMUNICATING'), value: 'NC' },
    { label: _('PRESUMED_OUT_OF_SERVICE'), value: 'HS' },
    { label: _('UNKNOWN'), value: 'NULL' },
  ].sort(sorter);
  filters.set(
    'id',
    new TableFilter<string>({
      key: 'id',
      label: _('COL_ID'),
      prompt: (currentValue) => tableFilterService.promptTextFilter(_('COL_ID'), currentValue),
      displayValue: (value) => value,
    })
  );

  filters.set(
    'compteur',
    new TableFilter<string>({
      key: 'compteur',
      label: _('COL_COMPTEUR'),
      prompt: (currentValue) =>
        tableFilterService.promptTextFilter(_('COL_COMPTEUR'), currentValue),
      displayValue: (value) => value,
    })
  );

  filters.set(
    'module',
    new TableFilter<string>({
      key: 'module',
      label: _('COL_MODULE'),
      prompt: (currentValue) => tableFilterService.promptTextFilter(_('COL_MODULE'), currentValue),
      displayValue: (value) => value,
    })
  );

  filters.set(
    'type_de_module',
    new TableFilter<string>({
      key: 'type_de_module',
      label: _('COL_TYPE_DE_MODULE'),
      prompt: (currentValue) =>
        tableFilterService.promptTextFilter(_('COL_TYPE_DE_MODULE'), currentValue),
      displayValue: (value) => value,
    })
  );

  filters.set(
    'version_de_module',
    new TableFilter<string>({
      key: 'version_de_module',
      label: _('COL_VERSION_DE_MODULE'),
      prompt: (currentValue) =>
        tableFilterService.promptTextFilter(_('COL_VERSION_DE_MODULE'), currentValue),
      displayValue: (value) => value,
    })
  );

  filters.set(
    'mode_collecte_declare',
    new TableFilter<number[]>({
      key: 'mode_collecte_declare',
      label: _('COL_MODE_COLLECTE_DECLARE'),
      displayValue: (values: string[]) =>
        values.map((value) => modeOptions.find((o) => o.value === value)?.label).join(', '),
      prompt: (currentValue) =>
        tableFilterService.promptSelectFilter(
          _('COL_MODE_COLLECTE_DECLARE'),
          currentValue,
          modeOptions,
          true
        ),
    })
  );

  filters.set(
    'raccorde',
    new TableFilter<number[]>({
      key: 'raccorde',
      label: _('COL_RACCORDE'),
      displayValue: (values: number[]) =>
        values.map((value) => raccordeOptions.find((o) => o.value === value)?.label).join(', '),
      prompt: (currentValue) =>
        tableFilterService.promptSelectFilter(
          _('COL_RACCORDE'),
          currentValue,
          raccordeOptions,
          true
        ),
    })
  );

  filters.set(
    'date_derniere_trame_recue',
    new TableFilter<[from: Date, to: Date]>({
      key: 'date_derniere_trame_recue',
      label: _('COL_DATE_DERNIERE_TRAME_RECUE'),
      prompt: (currentValue) =>
        tableFilterService.promptDateRangeFilter('', currentValue, {
          maxLimit: new Date(),
        }),
      displayValue: (currentValue) => TableFilter.dateRangeDisplay(currentValue, formatter),
    })
  );

  filters.set(
    'etat_supervision',
    new TableFilter<number[]>({
      key: 'etat_supervision',
      label: _('COL_ETAT_SUPERVISION'),
      displayValue: (values: Array<string | null>) =>
        values
          .map((value) => etatSupervisionOptions.find((o) => o.value === value)?.label)
          .join(', '),
      prompt: (currentValue) =>
        tableFilterService.promptSelectFilter(
          _('COL_ETAT_SUPERVISION'),
          currentValue,
          etatSupervisionOptions,
          true
        ),
    })
  );

  filters.set(
    'dateref_index_livre',
    new TableFilter<[from: Date, to: Date]>({
      key: 'dateref_index_livre',
      label: _('COL_DATEREF_INDEX_LIVRE'),
      prompt: (currentValue) =>
        tableFilterService.promptDateRangeFilter('', currentValue, {
          maxLimit: new Date(),
        }),
      displayValue: (currentValue) => TableFilter.dateRangeDisplay(currentValue, formatter),
    })
  );

  filters.set(
    'date_livraison',
    new TableFilter<[from: Date, to: Date]>({
      key: 'date_livraison',
      label: _('COL_DATE_LIVRAISON'),
      prompt: (currentValue) =>
        tableFilterService.promptDateRangeFilter('', currentValue, {
          maxLimit: new Date(),
        }),
      displayValue: (currentValue) => TableFilter.dateRangeDisplay(currentValue, formatter),
    })
  );

  filters.set(
    'ok_1j_4h',
    new TableFilter<number[]>({
      key: 'ok_1j_4h',
      label: _('COL_OK_1J_4H'),
      displayValue: (values: number[]) =>
        values.map((value) => yesNoOptions.find((o) => o.value === value)?.label).join(', '),
      prompt: (currentValue) =>
        tableFilterService.promptSelectFilter(_('COL_OK_1J_4H'), currentValue, yesNoOptions, true),
    })
  );

  return filters;
}
