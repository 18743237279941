import { AsyncPipe } from '@angular/common';
import { Component, DestroyRef, OnInit, ViewChild, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { TranslocoPipe, TranslocoService, translate as _ } from '@ngneat/transloco';
import {
  AlertComponent,
  CardComponent,
  MessengerService,
  blueZodiac,
  cantaloupe,
  cornflower,
  frenchManicure,
  getLabel,
  hintOfMint,
  pastelGreen,
} from '@web/birdz-angular';
import { Tooltip, TooltipMap } from '@web/birdz-angular/highcharts';
import { MapClusterOptions, MapComponent, MapMarker } from '@web/birdz-angular/map';
import { Point } from 'app/interfaces/point.interface';
import { Api } from 'app/services/api.service';
import { FilterService } from 'app/services/filter.service';
import { UserService } from 'app/services/user.service';
import { environment } from 'environments/environment';
import * as L from 'leaflet';
import { EMPTY, Observable, finalize, iif, switchMap, tap } from 'rxjs';

@Component({
  selector: 'app-card-map',
  standalone: true,
  imports: [CardComponent, TranslocoPipe, AsyncPipe, AlertComponent, MapComponent],
  templateUrl: './card-map.component.html',
  styleUrl: './card-map.component.scss',
})
export class CardMapComponent implements OnInit {
  hasPerimeter$: Observable<boolean>;
  private destroyRef = inject(DestroyRef);
  isLoading = 0;
  hasNonGeocodedPds = false;
  canAccessPds = false;
  markers: MapMarker[] | null = null;
  readonly colors: Record<string, string> = {
    NON_EQUIPPED: frenchManicure,
    WB: hintOfMint,
    NOT_CONNECTED: cantaloupe,
    FUNCTIONAL: pastelGreen,
    NONFUNCTIONAL_HS: cornflower,
    NONFUNCTIONAL_NC: blueZodiac,
    UNKNOWN: '#aaa',
  };
  getIconSize = function (zoom: number) {
    if (zoom < 10) {
      return 46;
    }
    if (zoom > 14) {
      return 30;
    }
    if (zoom > 16) {
      return 20;
    }
    return 40;
  };
  mapInitOptions: any = {
    zoom: 6,
    maxZoom: 21,
    maxNativeZoom: 21,
    center: L.latLng([46.468776133052145, 2.5327107015625643]),
    popupOptions: {
      closeButton: true,
      closeOnClick: false,
    },
    reinitMapOnChange: false,
  };
  clusterOptions: MapClusterOptions = {
    spiderfyOnMaxZoom: true,
    showCoverageOnHover: false,
    // @ts-ignore
    maxClusterRadius: function (zoom) {
      const radius = zoom > 16 ? 0 : 80;
      return radius;
    },
    iconSize: {
      width: this.getIconSize,
      height: this.getIconSize,
    },
    disableClusteringAtZoom: 26,
    getClassName: (cluster) =>
      [
        ...new Set(
          cluster
            .getAllChildMarkers()
            // @ts-ignore
            .map((marker) => `color-${marker.data.color.replace('#', '')}`)
        ),
      ].join(' '),
  };
  @ViewChild(MapComponent)
  private mapComponent: MapComponent | undefined;
  constructor(
    private filterService: FilterService,
    private api: Api,
    private messenger: MessengerService,
    private userService: UserService,
    private transloco: TranslocoService
  ) {
    this.hasPerimeter$ = this.filterService.hasPerimeter$;
  }

  ngOnInit() {
    this.fetchMapWhenFilterChanges();
    this.canAccessPds = this.userService.getUser()?.hasApplication('pds360') ?? false;
  }

  getPointColor(point: Point): string {
    return this.colors[point.state] ?? '#aaa';
  }

  setMarkers(points: Point[]) {
    this.markers = points.map((p) => {
      return {
        circle: {
          radius: 10,
          strokeWidth: 1,
          color: '#00000077',
          fillColor: this.getPointColor(p),
        },
        lat: p.lat,
        lng: p.lng,
        data: {
          id: p.id,
        },
      };
    });
    this.fitMap();
  }

  fitMap() {
    this.mapComponent?.fitMap((this.markers ?? []).map((m) => [m.lat, m.lng]));
  }

  getPdsTitle(): string {
    const pds = this.userService.getUser()?.getApplication('pds360') ?? null;
    if (!pds) {
      return '';
    }
    return getLabel(pds, this.transloco.getActiveLang());
  }

  getPdsLinkHTML(pdsId: string): string {
    if (this.canAccessPds) {
      const url: string = environment.pdsUrl(pdsId);
      const title: string = this.getPdsTitle();
      return `<a 
                title="${title}"
                href="${url}"
                style="vertical-align:middle;display:inline-block;margin-left:5px;"
                target="_blank">
                <img width="21" height="15" src="/assets/birdz/svg/icons/app_pds360.svg">
              </a>`;
    }
    return '';
  }

  markerOnClick = (marker: MapMarker, event: any) => {
    const id: string = marker.data.id as string;
    const popup = event.target.getPopup();
    const loader = `<div class="map-tooltip"><div class="map-tooltip__loader"><div></div></div></div>`;
    popup.setContent(loader);
    const content: TooltipMap[] = [];
    this.api
      .fetchPds(id)
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        this.messenger.showFriendlyErrorMessage(),
        tap((pds: any) => {
          const color = this.getPointColor(pds);
          const pdsLinkHTML = this.getPdsLinkHTML(pds.id);
          content.push(
            {
              label: _('POPUP_EQUIPMENT'),
              value: `${pds.compteur ?? '-'} ${pdsLinkHTML}`,
            },
            {
              label: _('POPUP_DEVICE'),
              value: `${pds.module ?? '-'} ${pdsLinkHTML}`,
            },
            {
              label: _('POPUP_STATE') + `<span class="circle" style="--color:${color};"></span>`,
              value: _(`STATE_${pds.state}`),
            },
            {
              label: _('POPUP_ADDRESS'),
              value: pds.address,
            }
          );
        }),
        finalize(() => {
          const tooltip = new Tooltip();
          tooltip.mapRow(content);
          popup.setContent(tooltip.html());
        })
      )
      .subscribe();
  };

  reset() {
    this.markers = [];
    this.hasNonGeocodedPds = false;
  }

  fetchMapWhenFilterChanges() {
    this.filterService.filter$
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        tap(() => this.reset()),
        tap(() => this.isLoading++),
        switchMap(() =>
          iif(
            () => this.filterService.hasPerimeter(),
            this.api
              .fetchMap(this.filterService.getPerimeter())
              .pipe(takeUntilDestroyed(this.destroyRef), this.messenger.showFriendlyErrorMessage()),
            EMPTY
          ).pipe(finalize(() => this.isLoading--))
        ),
        tap((data) => (this.hasNonGeocodedPds = data.non_geocoded_pds > 0)),
        tap((data) => this.setMarkers(data.pds))
      )
      .subscribe();
  }
}
