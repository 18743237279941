export const environment = {
  env: 'ppd',
  production: true,
  apiUrl: '/api',
  loginUrl: '/login/keycloak',
  loginUrlSilent: '/login/keycloak?silent',
  logoutUrl: 'https://ssoqlf.birdz.com/auth/realms/AppsByBirdz/protocol/openid-connect/logout',
  bypassOAuth: false,
  sentryDSN: 'https://3cf522dc04144d12a933fb057cf81ef2@sentry.birdz.com/44',
  sentryTracingOrigins: ['https://bdz-dtp-supervision-ppd.appspot.com'],
  sentryTracesSampleRate: 0.0,
  messagesUrl: '/api/birdz-messenger/messages',
  messagesCookiesDomain: null,
  pdsUrl: (pds: string) => `https://pds.ppd.apps.birdz.com?pds=${pds}`,
};
