import { AsyncPipe } from '@angular/common';
import { Component, DestroyRef, OnInit, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MAT_TOOLTIP_DEFAULT_OPTIONS, MatTooltipModule } from '@angular/material/tooltip';
import { TranslocoPipe, TranslocoService } from '@ngneat/transloco';
import {
  BirdzDataSource,
  CardComponent,
  DataSourceParams,
  FilterPipe,
  FormatDatePipe,
  FormatNumberPipe,
  FormatterService,
  JoinPipe,
  LogPipe,
  MessengerService,
  PluckPipe,
  TableColumn,
  TableFilter,
  TableFilterButtonComponent,
  TableFilterService,
  TableHelpButtonComponent,
  TableWrapperComponent,
} from '@web/birdz-angular';
import { getTableFilters } from 'app/components/card-details/card-details.conf';
import { Api } from 'app/services/api.service';
import { FilterService } from 'app/services/filter.service';
import { format } from 'date-fns';
import { Observable, combineLatest, filter, take, tap } from 'rxjs';

@Component({
  selector: 'app-card-details',
  standalone: true,
  providers: [
    {
      provide: MAT_TOOLTIP_DEFAULT_OPTIONS,
      useValue: {
        showDelay: 300,
      },
    },
  ],
  imports: [
    MatTableModule,
    PluckPipe,
    MatSortModule,
    MatTooltipModule,
    TranslocoPipe,
    TableWrapperComponent,
    CardComponent,
    FormatDatePipe,
    TableFilterButtonComponent,
    AsyncPipe,
    FormatNumberPipe,
    LogPipe,
    MatTooltipModule,
    TableHelpButtonComponent,
    FilterPipe,
    JoinPipe,
  ],
  templateUrl: './card-details.component.html',
  styleUrl: './card-details.component.scss',
})
export class CardDetailsComponent implements OnInit {
  isLoading = 0;
  displayedColumnsAsString = ['pds'];
  hasPerimeter$: Observable<boolean>;
  needPerimeter$: Observable<boolean>;
  filters: Map<string, TableFilter<any>> = new Map();
  // @ts-ignore
  columns: TableColumn[];
  // @ts-ignore
  dataSource: BirdzDataSource<Paginated<Pds>> = null;
  private destroyRef = inject(DestroyRef);
  isActive = (col: TableColumn) => col.active;
  constructor(
    private api: Api,
    private filterService: FilterService,
    private tableFilterService: TableFilterService,
    private formatter: FormatterService,
    private transloco: TranslocoService,
    private messenger: MessengerService
  ) {
    this.hasPerimeter$ = this.filterService.hasPerimeter$;
    this.needPerimeter$ = this.filterService.needPerimeter$;
  }

  ngOnInit() {
    this.initColumns();
    this.initTableFilters();
    this.initDataSource();
  }

  initDataSource() {
    this.dataSource = new BirdzDataSource((params: DataSourceParams) => {
      const filters: any = params.filters ?? {};
      const payload = {
        sort: params.sort,
        dateref_index_livre_min: filters.dateref_index_livre?.[0]
          ? format(filters.dateref_index_livre[0], 'yyyy-MM-dd')
          : null,
        dateref_index_livre_max: filters.dateref_index_livre?.[1]
          ? format(filters.dateref_index_livre[1], 'yyyy-MM-dd')
          : null,
        date_derniere_trame_recue_min: filters.date_derniere_trame_recue?.[0]
          ? format(filters.date_derniere_trame_recue[0], 'yyyy-MM-dd')
          : null,
        date_derniere_trame_recue_max: filters.date_derniere_trame_recue?.[1]
          ? format(filters.date_derniere_trame_recue[1], 'yyyy-MM-dd')
          : null,
        etat_supervision: filters.etat_supervision?.map((value: string) =>
          value === 'NULL' ? null : value
        ),
        date_livraison_min: filters.date_livraison?.[0]
          ? format(filters.date_livraison[0], 'yyyy-MM-dd')
          : null,
        date_livraison_max: filters.date_livraison?.[1]
          ? format(filters.date_livraison[1], 'yyyy-MM-dd')
          : null,
        id: filters.id,
        compteur: filters.compteur,
        module: filters.module,
        type_de_module: filters.type_de_module,
        version_de_module: filters.version_de_module,
        mode_collecte_declare: filters.mode_collecte_declare,
        raccorde: filters.raccorde,
        ok_1j_4h: filters.ok_1j_4h,
      };

      return this.api
        .fetchPdsIndex(this.filterService.toParams(), payload)
        .pipe(
          takeUntilDestroyed(this.destroyRef),
          this.messenger.showFriendlyErrorMessage(),
          take(1)
        );
    });

    // reload when needed
    combineLatest([this.filterService.filter$, this.transloco.langChanges$])
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        filter(() => this.filterService.hasPerimeter()),
        tap(() => {
          this.dataSource.reset().load();
        })
      )
      .subscribe();
  }

  initTableFilters() {
    this.filters = getTableFilters(this.tableFilterService, this.formatter);
  }

  initColumns() {
    this.columns = [
      'id',
      'date_calcul',
      'compteur',
      'module',
      'contrat',
      'nom_commune',
      'type_de_module',
      'version_de_module',
      'mode_collecte_declare',
      'raccorde',
      'date_derniere_trame_recue',
      'etat_supervision',
      'dernier_index_livre',
      'dateref_index_livre',
      'date_livraison',
      'ok_1j_4h',
      'motif_exclu',
    ].map((key) => {
      return {
        value: key,
        label: `col_${key}`.toUpperCase(),
        active: true,
      };
    });
  }

  resetColumns() {
    this.initColumns();
  }
}
