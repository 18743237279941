import { AsyncPipe } from '@angular/common';
import { Component, DestroyRef, OnInit, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MessengerService, Period } from '@web/birdz-angular';
import { CardEquippingComponent } from 'app/components/card-equipping/card-equipping.component';
import { CardIndexReportingComponent } from 'app/components/card-index-reporting/card-index-reporting.component';
import { CardRemoteReadingComponent } from 'app/components/card-remote-reading/card-remote-reading.component';
import { DashboardStats } from 'app/interfaces/dashboard-stats.interface';
import { IndexCollectionStats } from 'app/interfaces/index-collection-stats.interface';
import { Api } from 'app/services/api.service';
import { FilterService } from 'app/services/filter.service';
import {
  BehaviorSubject,
  EMPTY,
  Observable,
  combineLatest,
  delay,
  filter,
  finalize,
  switchMap,
  tap,
} from 'rxjs';

@Component({
  selector: 'app-page-dashboard',
  templateUrl: './page-dashboard.component.html',
  styleUrls: ['./page-dashboard.component.scss'],
  standalone: true,
  imports: [
    CardEquippingComponent,
    CardRemoteReadingComponent,
    CardIndexReportingComponent,
    AsyncPipe,
  ],
})
export class PageDashboardComponent implements OnInit {
  private destroyRef = inject(DestroyRef);
  hasPerimeter$: Observable<boolean>;
  stats: DashboardStats | null = null;
  indexStats: IndexCollectionStats | null = null;
  isLoadingIndexStats = 0;
  isLoadingStats = 0;
  period$ = new BehaviorSubject<Period | null>(null);
  constructor(
    private api: Api,
    private filterService: FilterService,
    private messenger: MessengerService
  ) {
    this.hasPerimeter$ = this.filterService.hasPerimeter$;
  }

  ngOnInit() {
    this.fetchStatistics();
    this.fetchIndexCollectionRates();
  }

  fetchStatistics() {
    this.filterService.filter$
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        tap(() => (this.stats = null)),
        filter(() => this.filterService.hasPerimeter()),
        tap(() => this.isLoadingStats++),
        switchMap(() =>
          this.api.fetchDashboardStatistics(this.filterService.toParams()).pipe(
            tap((stats) => (this.stats = stats)),
            takeUntilDestroyed(this.destroyRef),
            this.messenger.showFriendlyErrorMessage(),
            finalize(() => this.isLoadingStats--)
          )
        )
      )
      .subscribe();
  }

  fetchIndexCollectionRates() {
    combineLatest([this.filterService.filter$, this.period$])
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        delay(1),
        tap(() => (this.indexStats = null)),
        filter(([, period]) => period !== null && this.filterService.hasPerimeter()),
        tap(() => this.isLoadingIndexStats++),
        switchMap(([, period]) =>
          this.api.fetchIndexCollectionRates(this.filterService.toParams(), period as Period).pipe(
            tap((indexStats) => (this.indexStats = indexStats)),
            this.messenger.showFriendlyErrorMessage(EMPTY),
            takeUntilDestroyed(this.destroyRef),
            finalize(() => this.isLoadingIndexStats--)
          )
        )
      )
      .subscribe();
  }

  handlePeriodChange(period: Period) {
    this.period$.next(period);
  }
}
