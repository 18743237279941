import {
  Component,
  DestroyRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  inject,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatOptionModule } from '@angular/material/core';
import { MatDialog } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectChange, MatSelectModule } from '@angular/material/select';
import { TranslocoPipe, TranslocoService } from '@ngneat/transloco';
import {
  CardComponent,
  FormatDatePipe,
  FormatNumberPipe,
  FormatterService,
  Period,
  PeriodDisplayComponent,
  PeriodPrevNextComponent,
  PeriodRangePickerWithNavComponent,
  PeriodService,
  PeriodSnapModeComponent,
  Str,
  blueZodiac,
  cantaloupe,
  translateArray,
} from '@web/birdz-angular';
import { Locale, endOfMonth, format, parseISO } from 'date-fns';
import { enUS, fr } from 'date-fns/locale';
import * as Highcharts from 'highcharts';
import { HighchartsChartModule } from 'highcharts-angular';
import { tap } from 'rxjs';
import { fontFamily } from '../../config/constants.config';
import { IndexCollectionPoint } from '../../interfaces/index-collection-point.interface';
import { IndexCollectionStats } from '../../interfaces/index-collection-stats.interface';
import { ModalHelpIndexReportingComponent } from '../modal-help-index-reporting/modal-help-index-reporting.component';

@Component({
  selector: 'app-card-index-reporting',
  templateUrl: './card-index-reporting.component.html',
  styleUrls: ['./card-index-reporting.component.scss'],
  providers: [
    PeriodService,
    {
      provide: 'initialPeriodState',
      useFactory: () => {
        const now = new Date();
        return {
          min: new Date(2020, 0, 1, 0, 0, 0),
          max: endOfMonth(now),
          snapTo: 'month',
        };
      },
    },
  ],
  standalone: true,
  imports: [
    MatButtonModule,
    MatIconModule,
    PeriodRangePickerWithNavComponent,
    MatProgressSpinnerModule,
    MatFormFieldModule,
    MatSelectModule,
    FormsModule,
    MatOptionModule,
    HighchartsChartModule,
    PeriodPrevNextComponent,
    TranslocoPipe,
    FormatNumberPipe,
    FormatDatePipe,
    CardComponent,
    FormatNumberPipe,
    PeriodSnapModeComponent,
    PeriodDisplayComponent,
  ],
})
export class CardIndexReportingComponent implements OnChanges, OnInit {
  private destroyRef = inject(DestroyRef);
  @Input() stats?: IndexCollectionStats | null = null;
  @Input({ required: true }) hasPerimeter?: boolean;
  @Input({ required: true }) isLoading?: boolean;
  @Output() periodChange = new EventEmitter<Period>();
  chartOptions?: any;
  Highcharts = Highcharts;
  updateFlag = false;
  latestIndexCollectionRate: null | number = null;
  latestNetworkEfficiencyRate: null | number = null;
  // @ts-ignore
  days: number = 1;
  options = [1];

  constructor(
    private periodService: PeriodService,
    private transloco: TranslocoService,
    private formatter: FormatterService,
    private dialog: MatDialog
  ) {}
  ngOnInit() {
    this.setDays(1);
    this.handleLanguageChange();
    this.emitPeriodOnChange();
  }

  ngOnChanges() {
    this.updateChart();
    this.updateFigures();
  }

  help() {
    this.dialog.open(ModalHelpIndexReportingComponent, { maxWidth: '900px' });
  }

  updateFigures() {
    const latestPoint: IndexCollectionPoint | null =
      (this.stats?.points ?? [])
        .slice()
        .sort(Str.sortBy('date'))
        .reverse()
        .find((point: any) => point.date !== null) ?? null;
    this.latestIndexCollectionRate =
      (latestPoint?.[
        `index_collection_rate_ok${this.days}j` as keyof IndexCollectionPoint
      ] as number) ?? null;
    this.latestNetworkEfficiencyRate =
      (latestPoint?.[
        `network_efficiency_rate_ok${this.days}j` as keyof IndexCollectionPoint
      ] as number) ?? null;
  }

  handleLanguageChange() {
    this.transloco.langChanges$
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        tap(() => this.updateChart())
      )
      .subscribe();
  }

  emitPeriodOnChange() {
    this.periodService.period$
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        tap((period) => this.periodChange.emit(period))
      )
      .subscribe();
  }

  handleDayChange(days: MatSelectChange) {
    this.setDays(days.value);
    this.updateFigures();
    this.updateChart();
  }

  setDays(days: number) {
    this.days = days;
    if (this.days === 1) {
      this.periodService.snapTo = 'month';
    } else if ([2, 7, 28].includes(this.days)) {
      this.periodService.snapTo = 'year';
    }
  }

  resetChart() {
    this.chartOptions = null;
  }

  getLocale(): Locale {
    const lang = this.transloco.getActiveLang();
    if (lang === 'fr') {
      return fr;
    }
    return enUS;
  }

  getTickConfig(): any {
    const tick: any = {};
    if (this.stats?.granularity === 'day') {
      tick.tickInterval = Math.floor(this.stats.points.length * 0.05 + 1);
    }
    if (this.stats?.granularity === 'month') {
      tick.tickInterval = 1;
    }
    return tick;
  }

  getXFormatter(): any {
    let xFormatter: any = {};
    const locale = this.getLocale();
    const formatter = this.formatter;
    if (this.stats?.granularity === 'month') {
      xFormatter = (x: string, separator = ' ') => {
        const date = parseISO(x);
        const month = Str.capitalizeFirstLetter(format(date, 'MMMM', { locale }));
        const year = format(date, 'yyyy', { locale });
        return `${month}${separator}${year}`;
      };
    } else if (this.stats?.granularity === 'day') {
      const year = /\/?\d{4}\/?/;
      xFormatter = (x: string) => formatter.date(x).replace(year, '');
    }
    return xFormatter;
  }

  getEventsConfig(): any {
    let config = {};
    if (this.stats?.granularity === 'month') {
      config = {
        events: {
          click(e: any) {
            const date = e.point?.category;
            if (date) {
              document.dispatchEvent(
                new CustomEvent('period.goto', {
                  detail: {
                    date: new Date(date),
                    snapTo: 'month',
                  },
                })
              );
            }
          },
        },
        cursor: 'pointer',
      };
    }
    return config;
  }

  updateChart() {
    if (!this.stats?.points) {
      return this.resetChart();
    }
    const __ = translateArray([
      'INDEX',
      'NETWORK_EFFICIENCY_RATE',
      'COLON',
      'NO_DATA_AVAILBLE_FOR_THIS_PERIOD',
    ]);
    const formatter = this.formatter;
    const xAxisCategories = this.stats.points.map((p: any) => p.granular_date);
    const indexCollectionKey = `index_collection_rate_ok${this.days}j`;
    const networkEfficencyKey = `network_efficiency_rate_ok${this.days}j`;
    const xFormatter = this.getXFormatter();
    const tickConfig = this.getTickConfig();
    const eventsConfig = this.getEventsConfig();
    const indexData = this.stats.points.map((point: any) => point[indexCollectionKey]);
    const networkEfficiencyData = this.stats.points.map((point: any) => point[networkEfficencyKey]);
    const yFormatter = (y: number) => formatter.number(y, '0.0-2');
    this.chartOptions = {
      credits: {
        enabled: false,
      },
      title: {
        text: null,
      },
      chart: {
        plotBackgroundColor: null,
        plotBorderWidth: null,
        plotShadow: false,
        type: 'line',
        style: {
          fontFamily: fontFamily,
        },
      },
      tooltip: {
        // @ts-ignore
        formatter: function () {
          // @ts-ignore
          const y = this.point.y;
          // @ts-ignore
          const name = this.series.name;
          // @ts-ignore
          const x = this.x;
          return `<b>${xFormatter(x)}</b><br>${name}: <b>${yFormatter(y)}%</b>`;
        },
      },
      legend: {
        // @ts-ignore
        labelFormatter: function () {
          // @ts-ignore
          return `<div class="legend__title">${this.name}</div>`;
        },
      },
      xAxis: {
        showEmpty: true,
        ...tickConfig,
        categories: xAxisCategories,
        labels: {
          // @ts-ignore
          formatter: function () {
            // @ts-ignore
            return xFormatter(this.value, '<br>');
          },
        },
        min: 0, // forces the xAxis'ticks to de displayed when there is no data
        max: xAxisCategories.length - 1, // forces the xAxis'ticks to de displayed when there is no data
      },
      lang: {
        noData: __.NO_DATA_AVAILBLE_FOR_THIS_PERIOD,
      },
      noData: {
        style: {
          fontWeight: 'normal',
          fontSize: '16px',
          color: '#00000099',
        },
      },
      yAxis: {
        min: 0,
        max: 100,
        title: {
          text: null,
        },
        labels: {
          format: '{value} %',
        },
      },
      plotOptions: {
        series: {
          animation: false,
          marker: {
            symbol: 'circle',
          },
          lineWidth: 2,
          ...eventsConfig,
        },
      },
      series: [
        {
          name: __.INDEX,
          color: blueZodiac,
          data: indexData.every((y) => y === null) ? [] : indexData, // we provide [] to display the "noData" message
        },
        {
          name: __.NETWORK_EFFICIENCY_RATE,
          color: cantaloupe,
          data: networkEfficiencyData.every((y) => y === null) ? [] : networkEfficiencyData, // we provide [] to display the "noData" message
        },
      ],
    };
    this.updateFlag = true;
  }
}
