<birdz-modal>
  <div modal-title>{{ 'HELP_INDEX_1' | transloco }}</div>
  <div modal-body class="body">
    <p>{{ 'HELP_INDEX_2' | transloco }}{{ 'COLON' | transloco }}</p>
    <ul>
      <li>{{ 'HELP_INDEX_3' | transloco }}</li>
      <ul>
        <li>
          {{ 'HELP_INDEX_4' | transloco }}<br />
          <code>{{ 'HELP_INDEX_4B' | transloco }}</code>
        </li>
      </ul>
      <li>{{ 'HELP_INDEX_5' | transloco }}</li>
      <ul>
        <li>
          {{ 'HELP_INDEX_6' | transloco }}<br />
          <code>{{ 'HELP_INDEX_6B' | transloco }}</code>
        </li>
      </ul>
    </ul>
    <p>{{ 'HELP_INDEX_7' | transloco }}</p>
    <p>{{ 'HELP_INDEX_8' | transloco }}</p>
    <p>{{ 'HELP_INDEX_9' | transloco }}</p>
  </div>
</birdz-modal>
