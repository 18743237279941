<birdz-card [isLoading]="isLoading" [isEmpty]="!hasPerimeter">
  <div card-title>
    {{ 'HISTORICIZED_RATE_OF_INDEX_COLLECTION' | transloco }}
    <button class="help" mat-icon-button (click)="help()">
      <mat-icon>info</mat-icon>
    </button>
  </div>
  <div card-subtitle>
    @if(stats?.granularity === 'month') { @if (stats?.date) { {{ 'DATE_AT' | transloco }} {{
    (stats?.date ?? '') | formatDate }} } @else {
    <birdz-period-display />
    } } @else if (stats?.granularity === 'day') {

    <birdz-period-display />} @else { &nbsp;}
  </div>
  <div card-empty>{{ "PLEASE_SELECT_PERIMETER" | transloco }}</div>
  <div card-body>
    <div class="controls">
      <!-- time navigation -->
      <birdz-period-range-picker-with-nav />
      <birdz-period-snap-mode [options]="['year', 'month']" class="mode" />
      <!-- days -->
      <mat-form-field>
        <mat-label>{{ 'INDEX_COLLECTION_DAYS' | transloco }}</mat-label>
        <mat-select [(ngModel)]="days" (selectionChange)="handleDayChange($event)">
          @for(option of options; track option) {
          <mat-option [value]="option"> {{ 'DAYS' | transloco: {count:option} }} </mat-option>
          }
        </mat-select>
      </mat-form-field>
    </div>

    <!-- chart -->
    <div class="wrapper">
      @if (chartOptions) {
      <highcharts-chart
        [Highcharts]="Highcharts"
        [(update)]="updateFlag"
        class="chart"
        [options]="chartOptions"
      ></highcharts-chart>
      } @else if (!isLoading) {
      <div class="placeholder">{{ 'PLEASE_CHOSE_A_SHORTER_PERIOD' | transloco }}</div>
      }
    </div>

    <div class="figures">
      <!-- index collection -->
      <div class="figure figure--index">
        @if(!isLoading) { @if (latestIndexCollectionRate !== null) {
        <div>
          {{ latestIndexCollectionRate | formatNumber: '0.0-1' }}% {{ 'OF_INDEX_COLLECTION' |
          transloco }}
        </div>
        <div class="sub">
          {{ 'INDEX_COLLECTION_DAYS' | transloco }} {{ 'DAYS' | transloco: {count:days} }}
        </div>
        } @else {
        <div class="no-data">
          <div>{{ 'INDEX' | transloco }}</div>
          <div class="sub">{{ 'NO_DATA_AVAILBLE_FOR_THIS_PERIOD' | transloco }}</div>
        </div>
        } } @else {
        <mat-spinner diameter="16"></mat-spinner>
        }
      </div>

      <!-- network efficiency -->
      <div class="figure figure--network">
        @if(!isLoading) { @if(!latestNetworkEfficiencyRate !== null) {
        <div>
          {{ latestNetworkEfficiencyRate | formatNumber: '0.0-1' }}% {{ 'NETWORK_EFFICIENCY_RATE' |
          transloco }}
        </div>
        <div class="sub">
          {{ 'INDEX_COLLECTION_DAYS' | transloco }} {{ 'DAYS' | transloco: {count:days} }}
        </div>
        } @else {
        <div class="no-data">
          <div>{{ 'NETWORK_EFFICIENCY_RATE' | transloco }}</div>
          <div class="sub">{{ 'NO_DATA_AVAILBLE_FOR_THIS_PERIOD' | transloco }}</div>
        </div>
        } } @else {
        <mat-spinner diameter="16"></mat-spinner>
        }
      </div>
    </div>
  </div>
</birdz-card>
