import { Component } from '@angular/core';
import { TranslocoPipe } from '@ngneat/transloco';
import { ModalComponent } from '@web/birdz-angular';

@Component({
  selector: 'app-modal-help-remote-reading',
  templateUrl: './modal-help-remote-reading.component.html',
  styleUrls: ['./modal-help-remote-reading.component.scss'],
  standalone: true,
  imports: [TranslocoPipe, ModalComponent],
})
export class ModalHelpRemoteReadingComponent {}
